.disciplines {
	background-color: $navigation;
	display: flex;
	justify-content: center;

	.disciplines-desktop {
		margin: 12rem auto 12rem auto;
		width: 70%;

		.cursor, .image_block {
			position: fixed;
			top: 0;
			left: 0;
			pointer-events: none;
		}
		.image_block {
			width: 250px;
			overflow: hidden;
			background-size: cover;
			background: radial-gradient(50% 50% at 50% 50%, #4E45AF 0%, #362CAE 100%) 50% 50%;

			img	{
				width: 100%;
				height: auto;
			}
		}

		svg {
			z-index: 9999;
			opacity: 0.3
		}


		ul {
			li {
				display: flex;
				flex-direction: column;
				border-bottom: 1px solid #6B5CBE;
				&.active, &:hover {
					border-image: url("../images/border.svg") 10;
				}
				a {
					padding: 13px 0;
					display: block;
					color: #383065;
					text-transform: uppercase;
					font-size: 54px;
					font-weight: 600;
					&.active, &:hover {
						color: $white;
						z-index: 8888;
					}
				}
			}
		}
	}
}
.discipline-page {

	.discipline-title {
		margin: auto auto 5rem;
		position: relative;
		&:before {
			content: '';
			background: linear-gradient(180deg, rgba(10, 0, 51, 0.26) 0%, rgba(10, 0, 51, 0) 100%);
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 1;
		}
		.title {
			padding: 15rem 8rem 5rem;
			position: relative;
			display: flex;
			h1 {
				span {
					cursor: pointer;
					&:before {
						content: url("../images/button_round.svg");
						display: block;
						width: 40px;
						height: 40px;
						margin-right: 20px;
					}
					&:hover {
						display: block;
						content: url("../images/button_round_hover.svg");
						width: 40px;
						height: 40px;
						transform: translate(1px, 1px);	
						margin-right: 20px;
						cursor: pointer;
					}
				}
				display: flex;
				z-index: 10;
				font-weight: 600;
				font-size: 4rem;
			}
		}
		svg {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
		}
	}

	.content .infinite-scroll-component {
		display: grid;
		grid-gap: 20px;
		grid-auto-flow: dense;
		grid-template-columns: repeat(5, 1fr);
		width: 90%;
		margin-bottom: 2rem;
		margin-left: auto;
		margin-right: auto;
		.video {
			cursor: pointer;
			position: relative;
			width: 100%;
			margin-right: 10px;

			a {
				color: white;
				text-decoration: none;

				&:before {
					//filter color
					content: "";
					width: 100%;
					height: 100%;
					position: absolute;
					opacity: 0;
					background: rgba(31, 26, 86, 0.58);
					transition: all ease 0.5s;
				}

				> .image {
					display: block;
					height: auto;
					width: 100%;
					border-radius: 5px 5px 0 0;
					padding-bottom: 105px;
				}

				.title-container {
					background-color: #191549;
					position: absolute;
					bottom: -2px;
					text-transform: capitalize;
					font-size: 1.8rem;
					padding: 0.8rem 1rem 2rem;
					height: 82px;
					left: 0;
					right: 0;
					border-bottom-right-radius: 5px;
					border-bottom-left-radius: 5px;
					transition: all ease 0.5s;

					.play {
						position: absolute;
						width: 5rem;
						height: 5rem;
						top: -2.5rem;
						right: 1rem;
						opacity: 0;
						transition: all ease 0.5s;

						.play_button {
							height: 100%;
							width: 100%;
							cursor: pointer;

							.play_sign, .round {
								transition: all ease-in-out .5s;
							}
							&:hover {
								.round {
									fill: #ffffff;
								}
								.play_sign {
									fill: #362CAE;
								}
							}
						}
					}

					.video-date {
						display: grid;
						grid-template-columns: 33.33% 33.33% 33.33%;
						font-size: 10px;
						line-height: 12px;
						padding-bottom: 5px;

						.line {
							svg {
								width: 100%;
								vertical-align: middle;
							}
						}

						.date {
							text-align: center;
						}
					}

					h4 {
						line-height: 2rem;
						font-size: 14px;
					}

					.video-description {
						margin-top: 0.5rem;
						font-size: 1.2rem;
						line-height: 1.4rem;
						height: 0;
						overflow: hidden;
						transition: height ease 0.5s;
					}
				}
			}
			&:hover {
				a:before {
					opacity: 1 !important;
				}
				.video-description {
					height: auto !important;
				}
				.play {
					opacity: 1 !important;
				}
				.title-container {
					height: 40% !important;
				}
			}
		}
	}
}
@media (max-width: $desktop) {
	.discipline-page, .disciplines {
		.content .infinite-scroll-component {
			grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
		}
	}
}

@media (max-width: $mobile) {
	.discipline-page, .disciplines {
		.discipline-title {
			margin: auto auto 3rem !important;
			.title {
				padding: 9rem 1rem 2rem !important;
				h1 {
					font-size: 5vw !important;
					line-height: 40px;
				}
			}
			svg {
				display: none;
			}
		}
		.content .infinite-scroll-component {
			grid-template-columns: repeat(2, 1fr) !important;
			.video a {
				.title-container {
					padding: .4rem .5rem 1rem;
					h4 {
						font-size: 12px;
					}
				}
			}

		}
		.categories {
			grid-template-columns: repeat(2, 1fr) !important;
			display: grid;
			grid-gap: 20px;
			margin: 1rem 2rem;
			.category {
				position: relative;
				h4 {
					position: absolute;
					left: 0;
					right: 0;
					bottom: 2rem;
					font-weight: 700;
					text-align: center;
				}
			}
			img {
				width: 100%;
			}
		}
	}

}